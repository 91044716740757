// import React from 'react';
// import Swipeable from "react-swipy"

// const wrapperStyles = {position: "relative", width: "250px", height: "250px"};
// const actionsStyles = {
//   display: "flex",
//   justifyContent: "space-between",
//   marginTop: 12,
// };

// export default class SwipeCard extends React.Component {
//     constructor(){
//         super()
//         this.state = {
//             cards: ["Jovani", "Aston", "Jason", "Matt", "Aaron","Xin","Gex"],
//         }
//     }

//     remove = () =>
//         this.setState(({cards}) => ({
//             cards: cards.slice(1, cards.length),
//         }));

//     render(){
//         const {cards} = this.state;
//         return (
//             <div>
//                 <div style={wrapperStyles}>
//                 {cards.length > 0 ? (
//                     <div style={wrapperStyles}>
//                     <Swipeable
//                         buttons={({left, right}) => (
//                         <div style={actionsStyles}>
//                             <span onClick={left} className="button is-danger">Reject</span>
//                             <span onClick={right} className="button is-primary">Accept</span>
//                         </div>
//                         )}
//                         onAfterSwipe={this.remove}
//                     >
//                         <div className="title is-1 fd-center">{cards[0]}</div>
//                     </Swipeable>
//                     {cards.length > 1 && <div zIndex={-1}>{cards[1]}</div>}
//                     </div>
//                 ) : (
//                     <div zIndex={-2}>No more cards</div>
//                 )}
//                 </div>
//             </div>
//         )
//     }
// }






import React from 'react';
import Swipeable from "react-swipy"
import { navigateTo } from 'gatsby';

// const wrapperStyles = {position: "relative", width: "250px", height: "250px"};
const actionsStyles = {
  display: "flex",
  justifyContent: "space-between",
  marginTop: 12,
};

export default class SwipeCard extends React.Component {
    constructor(){
        super()
        this.state = {
            cards: ["Jovani", "Aston", "Jason", "Matt", "Aaron","Xin","Gex"],
        }
    }

    remove = () =>
        this.setState(({cards}) => ({
            cards: cards.slice(1, cards.length),
        }));

    render(){
        const {cards} = this.state;
        return (
            <div>
                <div>
                {cards.length > 0 ? (
                    <div>
                    <Swipeable
                        buttons={({left, right}) => (
                        <div style={actionsStyles}>
                            <span onClick={left} className="button is-danger">Reject</span>
                            <span onClick={right} className="button is-primary">Accept</span>
                        </div>
                        )}
                        onAfterSwipe={this.remove}
                    >
                        <div className="title is-1 fd-center">{cards[0]}</div>
                    </Swipeable>
                    {cards.length > 1 && <div zIndex={-1}>{cards[1]}</div>}
                    </div>
                ) : (
                    <div zIndex={-2}>
                        <p className="title is-5">All done!</p>
                    </div>
                )}
                </div>
            </div>
        )
    }
}
