import React from 'react'
// import { Link } from 'gatsby'
import Layout from '../components/layout'
import SwipeCard from '../components/swipecard'

const SwipeMatch = () => (
  <Layout>
    <SwipeCard></SwipeCard>
  </Layout>
)
export default SwipeMatch